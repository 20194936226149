<template>
  <div>
    <b-modal size="xl" hide-footer v-model="modalProducts" class="w-100" title="Productos">
      <b-row>
        <b-col md="12">
          <b-form-group label="Buscar producto :">
            <b-form-input type="text" ref="search_product" v-model="search_product" @keyup="SearchProducts"></b-form-input>
            
          </b-form-group>
        </b-col>
        <b-col md="12">
          <div class="table-responsive mt-3">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th width="5%"  rowspan="2" class="text-center align-middle">#</th>
                  <th width="8%"  rowspan="2" class="text-center align-middle">Código</th>
                  <th width="55%" rowspan="2" class="text-center align-middle">Nombre</th>
                  <th width="20%" class="text-center align-middle">UM</th>
                  <th width="10%" rowspan="2" class="text-center align-middle">Cantidad</th>
                  <th width="10%" rowspan="2" class="text-center align-middle">Acciones</th>
                </tr>
              </thead>
              <tbody v-for="(item, it) in products" :key="it">
                <tr>
                  <td class="text-center">{{ it + 1 }}</td>
                  <td class="text-left">{{ item.code }}</td>
                  <td class="text-left">{{ item.name +  " - " + item.presentation }}</td>
                  <td class="text-center">
                    <b-form-select :options="unit_measure" v-model="item.unit_measure"></b-form-select>
                  </td>
                  <td class="text-center">
                    <input type="number" value="1" :ref="'mODCantidad'+item.id_product" class="form-control">
                  </td>
                  <td class="text-center">
                      <button type="button" @click="AddProduct(item.id_product)" class="btn btn-info">
                        <i class="fas fa-plus-square"></i>
                      </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<style>



</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// import Notifications from 'vue-notification/dist/ssr.js';


export default {
  name: "ModalsProduct",
  data() {
    return {
        modalProducts:false,
        module:'ReferralGuide',
        role:0,
        search_product:'',
        products: [],
        unit_measure:[
        {value:'4A',text:'BOBINAS'},
        {value:'BJ',text:'BALDE'},
        {value:'BLL',text:'BARRILES'},
        {value:'BG',text:'BOLSA'},
        {value:'BO',text:'BOTELLAS'},
        {value:'BX',text:'CAJA'},
        {value:'CMK',text:'CENTIMETRO CUADRADO'},
        {value:'CMQ',text:'CENTIMETRO CUBICO'},
        {value:'CMT',text:'CENTIMETRO LINEAL'},
        {value:'CEN',text:'CIENTO DE UNIDADES'},
        {value:'CY',text:'CILINDRO'},
        {value:'DZN',text:'DOCENA'},
        {value:'DZP',text:'DOCENA POR 10**6'},
        {value:'BE',text:'FARDO'},
        {value:'GLI',text:'GALON INGLES (4,545956L)'},
        {value:'GRM',text:'GRAMO'},
        {value:'KGM',text:'KILOGRAMO'},
        {value:'CA',text:'LATAS'},
        {value:'LBR',text:'LIBRAS'},
        {value:'LTR',text:'LITRO'},
        {value:'MTR',text:'METRO'},
        {value:'MTK',text:'METRO CUADRADO'},
        {value:'MTQ',text:'METRO CUBICO'},
        {value:'MGM',text:'MILIGRAMOS'},
        {value:'MIL',text:'MILLARES'},
        {value:'UM',text:'MILLON DE UNIDADES'},
        {value:'ONZ',text:'ONZAS'},
        {value:'PF',text:'PALETAS'},
        {value:'PK',text:'PAQUETE'},
        {value:'GRM',text:'GRAMO'},
        {value:'PR',text:'PAR'},
        {value:'FOT',text:'PIES'},
        {value:'FTK',text:'PIES CUADRADOS'},
        {value:'FTQ',text:'PIES CUBICOS'},
        {value:'C62',text:'PIEZAS'},
        {value:'NIU',text:'UNIDAD (BIENES) '},
        {value:'ZZ',text:'UNIDAD (SERVICIOS)'},
        {value:'GRM',text:'US GALON (3,7843 L)'},
        {value:'GLL',text:'GRAMO'},
      ],
        
    };
  },
  created (){
    
  },
  mounted () {
    EventBus.$on('ModalProductsShow', (role) => {
      this.modalProducts = true;
      this.role = role;
  
    });
    
  },
  methods: {
      SearchProducts,
      AddProduct,
        ...mapActions('ReferralGuide',['mLoadAddReferralGuideDetail']),
      
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};


function AddProduct(id_product) {
  
    let quantity = this.$refs['mODCantidad'+id_product][0]['value'];
    let me = this;
    let url = this.url_base + "product/view/" + id_product;

    axios({
      method: "GET",
      url: url,
      headers: {token: this.token, module: this.module,role: this.role, },
    })
    .then(function (response) {
      if (response.data.status == 200) {
        let weight_unit = response.data.result.gross_weight.length == 0 ? 0 : response.data.result.gross_weight;
        let weight_total = parseFloat(weight_unit) * parseFloat(quantity);
        let detail = {
          id_product: response.data.result.id_product,
          code:response.data.result.code,
          name:response.data.result.name,
          presentation:response.data.result.presentation,
          unit_measure:response.data.result.unit_measure,
          igv:response.data.result.igv,
          existence_type:response.data.result.existence_type,
          quantity: parseFloat(quantity).toFixed(2),
          weight_unit: parseFloat(weight_unit).toFixed(2),
          weight_total: parseFloat(weight_total).toFixed(2),
        }
        me.mLoadAddReferralGuideDetail(detail);

        
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      console.log(error);
      // Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });

   
}
//Buscar productos
function SearchProducts() {
  let me = this;
  let search = this.search_product == "" ? "all" : this.search_product;
  let url = this.url_base + "search-products";
  let data = {
    search : search,
    stock: "all"
  };
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      token: this.token,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.products = response.data.result;
      } else {
        me.products = [];
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      
    });
}
</script>
