<template>
  <div class="col-md-12">
        <div class="table-responsive mt-3">
              <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                <thead class="">
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="8%" class="text-center">Codigo</th>
                    <th width="48%" class="text-center">Nombre</th>
                    <th width="13%" class="text-center">UM</th>
                    <th width="10%" class="text-center">Cantidad</th>
                    <th width="8%" class="text-center">Peso Unit.</th>
                    <th v-if="type != 'Complete'" width="5%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in referral_guide_detail" :key="it">
                  <tr>
                      <td class="align-middle text-center">{{ it + 1 }}</td>
                      <td class="align-middle text-center">{{ item.code }}</td>
                      <td class="align-middle text-left">{{ item.name + (item.presentation.length == 0 ? '':' - '+item.presentation) }}</td>
                      <td class="align-middle text-center"> 
                        <b-form-select :disabled="type == 'Complete'" :options="unit_measure" v-model="item.unit_measure"></b-form-select>
                      </td>
                      <td class="align-middle text-center">
                        <input type="number" step="any" :disabled="type == 'Complete'" @change="EditDetail(it)" class="form-control text-right" v-model="item.quantity">
                      </td>
                      <td class="align-middle text-right">{{ item.weight_unit }}</td>
                      <td v-if="type != 'Complete'" class="align-middle text-center">
                        <button type="button" @click="DeleteDetail(it)" class="btn btn-danger"><i class="fas fa-trash-alt"></i></button>  
                      </td>

                  </tr>
                </tbody>
              </table>
            </div>
            
  </div>
</template>
<style scoped>
.table-responsive{
  min-height: 15px !important;
}
</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from '@/assets/js/EventBus';
import CodeToName from "@/assets/js/CodeToName";
export default {
  name: "SaleDetail",
  props: ["type"],
  data() {
    return {
   unit_measure:[
        {value:'4A',text:'BOBINAS'},
        {value:'BJ',text:'BALDE'},
        {value:'BLL',text:'BARRILES'},
        {value:'BG',text:'BOLSA'},
        {value:'BO',text:'BOTELLAS'},
        {value:'BX',text:'CAJA'},
        {value:'CMK',text:'CENTIMETRO CUADRADO'},
        {value:'CMQ',text:'CENTIMETRO CUBICO'},
        {value:'CMT',text:'CENTIMETRO LINEAL'},
        {value:'CEN',text:'CIENTO DE UNIDADES'},
        {value:'CY',text:'CILINDRO'},
        {value:'DZN',text:'DOCENA'},
        {value:'DZP',text:'DOCENA POR 10**6'},
        {value:'BE',text:'FARDO'},
        {value:'GLI',text:'GALON INGLES (4,545956L)'},
        {value:'GRM',text:'GRAMO'},
        {value:'KGM',text:'KILOGRAMO'},
        {value:'CA',text:'LATAS'},
        {value:'LBR',text:'LIBRAS'},
        {value:'LTR',text:'LITRO'},
        {value:'MTR',text:'METRO'},
        {value:'MTK',text:'METRO CUADRADO'},
        {value:'MTQ',text:'METRO CUBICO'},
        {value:'MGM',text:'MILIGRAMOS'},
        {value:'MIL',text:'MILLARES'},
        {value:'UM',text:'MILLON DE UNIDADES'},
        {value:'ONZ',text:'ONZAS'},
        {value:'PF',text:'PALETAS'},
        {value:'PK',text:'PAQUETE'},
        {value:'GRM',text:'GRAMO'},
        {value:'PR',text:'PAR'},
        {value:'FOT',text:'PIES'},
        {value:'FTK',text:'PIES CUADRADOS'},
        {value:'FTQ',text:'PIES CUBICOS'},
        {value:'C62',text:'PIEZAS'},
        {value:'NIU',text:'UNIDAD (BIENES) '},
        {value:'ZZ',text:'UNIDAD (SERVICIOS)'},
        {value:'GRM',text:'US GALON (3,7843 L)'},
        {value:'GLL',text:'GRAMO'},
        {value:'TNE',text:'TONELADAS'},  
      ],
    };
  },
  mounted() {

  },
  methods: {
    EditDetail,
    DeleteDetail,
    NameUnitMeasure,
    ...mapActions('ReferralGuide',['mLoadEditReferralGuideDetail']),
    ...mapActions('ReferralGuide',['mLoadDeleteReferralGuideDetail']),
    
  },
  computed: {
    ...mapState('ReferralGuide',['referral_guide_detail']),
    
    
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};
function NameUnitMeasure(code) {
  return CodeToName.NameUnitMeasure(code);
}

function EditDetail(index) {
  this.mLoadEditReferralGuideDetail(index)
}

function DeleteDetail(index) {
this.mLoadDeleteReferralGuideDetail(index)
}
</script>
